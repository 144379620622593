import React, { Component, useState } from "react";
import {
  Div,
  I,
  Image,
  Input,
  InputDefault,
  Label,
  ModalNotification,
  Notification,
} from "../../components";
import MenuDepartamentoService from "../../service/MenuDepartamentoService";
import { Color, Type } from "../../utilities";

export function SelectIcon(props) {
  const { icons, setStateIcon } = props;
  const [selectIcon, setSelectIcon] = useState(false);
  //  const [active, setActive] = useState(false);
  const [descricao, setDescricao] = useState(props.itemIcon.descricao);

  // function getClassSearch() {
  //   return active ? "active" : "";
  // }

  function searchPrincipal() {
    return (
      <Div className="input-group" margin="2" marginBottom="3">
        <Div col="12" padding="0">
          <InputDefault
            responsive="12"
            label={`Editar Descrição do Menu`}
            name="descricao"
            placeholder="Escreva uma Descrição para o Menu"
            // required
            handlerChange={(e) => {
              setDescricao(e.target.value);
              props.itemIcon.descricao = e.target.value;
            }}
            value={descricao}
          />
        </Div>
        {/* <Div
          col="12"
          style={{ paddingRight: "42px" }}
          className={`translateXmenos15 translateXmais15 input-group-prepend searchbar d-flex ${getClassSearch()}`}
        >
          <I
            icon="fa fa-search search-table"
            bg="secondary"
            colorText="white"
            handlerClick={(e) => {
              setActive(!active);
            }}
            className="inputgrouptext d-flex"
          ></I>
          <Input
            display="block"
            type="search"
            placeholder="Pesquisar Ícone"
            className="inputsearch formcontrol"
            // handlerChange={(e) => { this.handleChangeSearch(e) }}
          ></Input>
        </Div> */}
      </Div>
    );
  }

  return (
    <Div
      col="12"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        maxHeight: "326px",
        overflow: "scroll",
      }}
    >
      {searchPrincipal()}
      {icons.map((item) => {
        return (
          <Div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
              borderRadius: "4px",
              cursor: "pointer",
              margin: "7px",
            }}
            handlerClick={() => {
              setSelectIcon(item.icone);
              setStateIcon(item.icone);
            }}
          >
            <Div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "4px",
                padding: "17px 38px",
                cursor: "pointer",
                width: "50px",
                height: "75px",
                margin: "7px",
                border:
                  selectIcon === item.icone
                    ? `3px solid ${Color.CUSTOM_COLORS.PRIMARY}`
                    : "1px solid #dee2e6",
              }}
            >
              <Image src={item.urlFoto} width="40px" />
            </Div>
            <Label value={item.nome} />
          </Div>
        );
      })}
    </Div>
  );
}

class IconDepartamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      editIcon: false,
      selectIcon: "",
      icons: this.props.icons,
      descricao: "",
    };

    this.setStateIcon = this.setStateIcon.bind(this);
  }

  setStateIcon(icon) {
    this.setState({
      selectIcon: icon,
    });
  }

  confirmIcon() {
    let obj = this.props.item;
    obj.icone = this.state.selectIcon;

    MenuDepartamentoService.putMenu([obj]).then((data) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.props.getMenu();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  modalIconsDisponiveis() {
    ModalNotification.renderMessage({
      title: "Ícones Disponiveis para Departamento",
      body: (
        <SelectIcon
          icons={this.props.icons}
          itemIcon={this.props.item}
          setStateIcon={this.setStateIcon}
        />
      ),
      handlerConfirm: () => this.confirmIcon(),
      handlerCancel: () => {},
      labelButtonCancel: "Fechar",
    });
  }

  render() {
    const { urlFoto } = this.props;
    return (
      <>
        <Div
          col="12"
          className="border"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
            zIndex: 998,
          }}
          handlerClick={() => this.modalIconsDisponiveis()}
          handleMouseEnter={() => this.setState({ editIcon: true })}
          handleMouseLeave={() => this.setState({ editIcon: false })}
        >
          <Image src={urlFoto} width="40px" />
        </Div>
        <I
          pointer
          title="Editar Icon do Departamento"
          icon={Type.ICON.EDIT}
          style={{
            color: Color.RGBA.LARANJA,
            fontSize: 23,
            //   position: "absolute",
            display: this.state.editIcon ? "flex" : "none",
            marginTop: "-62px",
            zIndex: 999,
          }}
          handlerClick={() => {}}
        />
      </>
    );
  }
}

export default IconDepartamento;
