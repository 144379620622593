import React, { Component } from "react";
import { connect } from "react-redux";
import { EmpresaUtils } from "..";
import {
  ButtonIconOutLine,
  ColorPickerDashboard,
  Div,
  I,
  Image,
  Label,
  Notification,
  Row,
} from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";
import IconImagem from "../../assets/images/icones/icon_exemplo_1.svg";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import ItemProdutoAbaixouPreco from "../produtos/ItemProdutoAbaixouPreco";
import EmpresaService from "../../service/EmpresaService";

const styles = {
  menuCarrinho: {
    borderRadius: 5,
    marginLeft: 15,
    color: "white",
    padding: 10,
    width: "40px",
  },
  labelValorTotalCarrinho: {
    color: Color.ECOMMERCE.AZUL,
  },
  labelSAC: {
    fontSize: 25,
  },
  labelServicoAtendimento: {
    width: 150,
    fontSize: 18,
    marginTop: 4,
  },
  labelTelefone: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 25,
  },
  labelEmail: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 18,
  },
  labelQuantidadeTotalItens: {
    position: "absolute",
    transform: `translate(-10px, 45px)`,
    width: "30px",
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  btnRegistrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: "#878787",
  },
  btnEntrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
  },
  boxLayout: {
    display: "flex",
    alignItems: "center",
    border: "1px solid " + Color.ECOMMERCE.CINZA_CLARO,
    padding: "20px",
    marginBottom: "20px",
    marginRight: "20px",
    justifyContent: "space-around",
  },
  boxContainerLayout: {
    marginLeft: "15px",
    alignItems: "flex-start !important",
  },
  labelDepartamento: {
    fontSize: 20,
    fontWeight: "bold",
  },
  imageIcon: {
    width: 28,
    height: 28,
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
  },
};

class ConfigGerais extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corPrimaria: "#e94e25",
      corSecundaria: "#C4C4C4",
      corTerciaria: "#343A40",
      corRodape: "#343A40",
      idEmpresa: "",
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.empresa).length !== 0) {
      this.setColor();
    }

    this.getParametrosEmpresa();
    this.getEmpresaPolitica();
    this.getEmpresaSobre();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.empresa !== this.props.empresa) {
      this.setColor();
    }
  }

  setColor = () => {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.props.empresa);
    let corTerciaria = EmpresaUtils.getCorTerciaria(this.props.empresa);
    let corRodape = EmpresaUtils.getCorRodape(this.props.empresa);

    this.setState({
      corPrimaria,
      corSecundaria,
      corTerciaria,
      corRodape,
    });
  };

  async getParametrosEmpresa() {
    await EmpresaService.getEmpresaParams().then(({ data }) => {
      this.setState({
        ...data,
      });
    });
  }

  async getEmpresaSobre() {
    await EmpresaService.getSobre().then(({ data }) => {
      this.setState({
        sobre: data.sobre,
      });
    });
  }

  async getEmpresaPolitica() {
    await EmpresaService.getPolitica().then(({ data }) => {
      this.setState({
        politica: data.politica,
      });
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getParametros = (empresa) => {
    let parametros = {};
    if (Utils.isValueValid(empresa)) {
      parametros = { ...empresa.parametros };
    }

    return parametros;
  };

  submitParametros = () => {
    const { corPrimaria, corRodape, corSecundaria, corTerciaria } = this.state;

    let newParams = {
      idEmpresa: this.state.idEmpresa,
      corPrimaria,
      corSecundaria,
      corTerciaria,
      corRodape,
    };

    EmpresaService.putEmpresa(newParams)
      .then((data) => {
        if (data.status) {
          Notification.success(data.mensagem);
          //  this.props.setLoadingProdutosPreco(true);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => {
        Notification.danger("Problemas na Atualização das informações gerais!");
      });
  };

  render() {
    let empresa = this.props.empresa || {};
    let parametros = this.getParametros(empresa);

    return (
      <Div col="12">
        <Row col="12" shadow className="border-left-primary">
          <Row col="6">
            <ColorPickerDashboard
              responsive="12"
              label="Cor primária"
              onChangeComplete={this.handleChange}
              name="corPrimaria"
              defaultValue={this.state.corPrimaria}
              required
            />

            <ColorPickerDashboard
              responsive="12"
              label="Cor secundária"
              onChangeComplete={this.handleChange}
              name="corSecundaria"
              defaultValue={this.state.corSecundaria}
              required
            />

            <ColorPickerDashboard
              responsive="12"
              label="Cor terciária"
              onChangeComplete={this.handleChange}
              name="corTerciaria"
              defaultValue={this.state.corTerciaria}
              required
            />

            <ColorPickerDashboard
              responsive="12"
              label="Cor Rodapé"
              onChangeComplete={this.handleChange}
              name="corRodape"
              defaultValue={this.state.corRodape}
              required
            />
          </Row>

          <Row col="6">
            <Div col="12" style={styles.boxContainerLayout}>
              <Div col="12" style={styles.boxLayout}>
                <Div>
                  <Label
                    value="Cadastre-se"
                    style={styles.btnRegistrar}
                    marginLeft="1"
                    family="SemiBold"
                    marginRight="1"
                    pointer
                  />
                  <Label
                    value="Entrar"
                    style={{
                      ...styles.btnEntrar,
                      color: this.state.corPrimaria,
                    }}
                    marginLeft="1"
                    marginRight="1"
                    pointer
                    family="SemiBold"
                  />
                </Div>
                <Div column pointer>
                  <I
                    icon={Type.ICON.QUESTION_CIRLE}
                    style={{ color: this.state.corPrimaria, fontSize: 30 }}
                    pointer
                  />
                  <Label value="Me ajuda" pointer family="Light" />
                </Div>

                <Div>
                  <Div>
                    <I
                      icon={Type.ICON.SALE_BASKET}
                      style={{
                        ...styles.menuCarrinho,
                        background: this.state.corPrimaria,
                      }}
                      pointer
                    />
                  </Div>
                </Div>
              </Div>

              <Div col="12" style={styles.boxLayout}>
                <Div className="d-flex flex-column" marginTop="5">
                  <Div inline="start" pointer>
                    <Image
                      src={IconImagem}
                      style={styles.imageIcon}
                      className="color-icon-menu"
                      pointer
                      width="32px"
                    />
                    <Label
                      pointer
                      family="SemiBold"
                      marginLeft="2"
                      style={{
                        ...styles.labelDepartamento,
                        color: this.state.corSecundaria,
                      }}
                      value="Departamento"
                    />
                  </Div>
                  <Label
                    className="border-label-departamento"
                    style={{ borderColor: this.state.corPrimaria }}
                  />
                  <Label className="border-bottom border-label-departamento-bottom" />

                  <Div
                    className="d-flex justify-content-start align-items-start align-self-start"
                    marginTop="2"
                  >
                    <ItemProdutoAbaixouPreco
                      history={this.props.history}
                      image={ImagemIndisponivel}
                      descricao={"Descrição do Produto"}
                      precoUnitario={9.99}
                      precoVenda={9.99}
                      codigoBarras={1231}
                      quantidade={0}
                      possuiQuantidadeFracionada={false}
                      disponivel={true}
                      quantidadeMinimaAtacado={1}
                      corTerciaria={this.state.corTerciaria}
                      corPrimaria={this.state.corPrimaria}
                      showHover={true}
                    />
                  </Div>
                </Div>
              </Div>

              <Div col="12" style={{ ...styles.boxLayout, marginRight: "0" }}>
                <Div
                  style={{
                    backgroundColor: this.state.corRodape,
                    padding: "20px",
                    color: "White",
                  }}
                  inline="between"
                >
                  <Div>
                    <Image src={parametros.urlLogoEmail} width="140px" />
                    <Label
                      marginTop="2"
                      value={`© 2021 ${Utils.capitalize(
                        empresa.fantasia || ""
                      )}`}
                      style={{ lineHeight: "15px" }}
                      family="Light"
                    />
                    <Label
                      value="Todos os direitos reservados"
                      style={{ lineHeight: "12px" }}
                      family="Light"
                    />
                    <Div inline="start">
                      <Label
                        value="CNPJ:"
                        style={{ color: "white" }}
                        family="Light"
                      />
                      <Label
                        style={{ color: "white" }}
                        family="Light"
                        marginLeft="2"
                        value={Mask.getValueMaskCpfCnpj(empresa.cpfCnpj)}
                      />
                    </Div>
                    <Label
                      value={`${empresa.enderecoCompleto} - CEP: ${empresa.cep}`}
                      family="Light"
                    />
                  </Div>
                </Div>
              </Div>
            </Div>
          </Row>
        </Row>

        <Div
          col="12"
          style={{
            textAlign: "right",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end ",
            margin: "0px 0 35px 0",
            padding: "20px 0px 0px 0px",
          }}
        >
          <ButtonIconOutLine
            value="Salvar"
            //icon={Type.ICON.PLUS}
            btn={Color.NODE.PRIMARY}
            handlerClick={() => this.submitParametros()}
          />
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigGerais);
