export default class Type {
  static INPUT = {
    SUBMIT: "submit",
    TEXT: "text",
    RADIO: "radio",
    DATE: "date",
    COLOR: "color",
    EMAIL: "email",
    FILE: "file",
    CHECKBOX: "checkbox",
    HIDDEN: "hidden",
    SELECT: "select",
    TIME: "time",
    PASSWORD: "password",
    RANGE: "range",
    PHONE: "tel",
    NUMBER: "number",
  };

  static POPOVER = {
    BUTTON: "button",
    LABEL: "label",
  };

  static CATALOGO = {
    TODOS: "TODOS",
    BLOQUEADOS: "BLOQUEADO",
    DISPONIVEL: "PUBLICADO",
    PENDENTES: "PENDENTE",
    NORMALIZADOS: "NORMALIZADO",
  };

  static ICON = {
    ARCHIVE: "fa fa-archive",
    X: "fa-x",
    XX: "fa-2x",
    XXX: "fa-3x",
    EDIT: "fa fa-pencil-square",
    DELETE: "fa fa-trash",
    USERR: "fa fa-user",
    USER: "fa fa-user-circle",
    USER_CIRCLE_O: "fa fa-user-circle-o",
    BELL: "fa fa-bell-o",
    KEY: "fa fa-key",
    CALENDAR: "fa fa-calendar",
    CLOSE: "fa fa-times-circle-o",
    CLOSEX: "fa fa-times",
    INFO: "fa fa-info-circle",
    WARNING: "fa fa-warning",
    SUCCESS: "fa fa-check",
    SUCCESS_CIRCLE: "fa fa-check-circle",
    CIRCLE: "fa fa-circle-thin",
    PLUS: "fa fa-plus",
    PLUS_SQUARE: "fa fa-plus-square",
    PLUS_CIRCLE: "fa fa-plus-circle",
    MINUS_SQUARE: "fa fa-minus-square",
    MINUS: "fa fa-minus",
    UPLOAD: "fa fa-upload",
    REFRESH: "fa fa-refresh",
    ARROW_CIRCLE_DOWN: "fa fa-arrow-circle-o-down",
    PAUSE: "fa fa-pause-circle-o",
    PLAY: "fa fa-play-circle-o",
    ARROW_CHEV_RIGHT: "fa fa-chevron-right",
    ARROW_CHEV_LEFT: "fa fa-chevron-left",
    ARROW_CHEV_DOWN: "fa fa-chevron-down",
    ARROW_CHEV_UP: "fa fa-chevron-up",
    ARROW_ANGLE_RIGHT: "fa fa-angle-right",
    ARROW_ANGLE_LEFT: "fa fa-angle-left",
    ARROW_ANGLE_DOWN: "fa fa-angle-down",
    LIST_UL: "fa fa-list-ul",
    ELLIPSIS_V: "fa fa-ellipsis-v",
    ELLIPSIS_H: "fa fa-ellipsis-h",
    SEARCH: "fa fa-search",
    HAND_RIGHT: "fa fa-hand-o-right",
    TICKET: "fa fa-ticket",
    LIST_ALT: "fa fa-list-alt",
    SALE_BASKET: "fa fa-shopping-basket",
    SHOPPING_CART: "fa fa-shopping-cart",
    SHOOPPING_BAG: "fa fa-shopping-bag",
    SMILE: "fa fa-smile-o",
    SMILE_SAD: "fa fa-frown-o",
    STAR: "fa fa-star-o",
    STAR_FULL: "fa fa-star",
    MONEY: "fa fa-money",
    FOOD: "fa fa-cutlery",
    REORDER: "fa fa-reorder",
    FILTER: "fa fa-filter",
    THUMBS_UP: "fa fa-thumbs-o-up",
    THUMBS_DOWN: "fa fa-thumbs-o-down",
    PAPER_PLANE_O: "fa fa-paper-plane-o",
    MAIL_FORWARD: "fa fa-share",
    SHARE: "fa fa-share-alt",
    CHART_LINE: "fa fa-line-chart",
    HEART_BEATH: "fa fa-heartbeat",
    USD: "fa fa-usd",
    PDF: "fa fa-file-pdf-o",
    CSV: "fa fa-file-excel-o",
    MOTORCYCLE: "fa fa-motorcycle",
    PERCENT: "fa fa-percent",
    TRUCK: "fa fa-truck",
    CLOCK: "fa fa-clock-o",
    EXCLAMATION: "fa fa-exclamation-circle",
    PHONE_SQUARE: "fa fa-phone-square",
    PRODUCT: "fa fa-product-hunt",
    FLOPPY: "fa fa-floppy-o",
    SIGNOUT: "fa fa-sign-out",
    REPLY: "fa fa-reply",
    GLOBE: "fa fa-globe",
    MAP_MARKER: "fa fa-map-marker",
    MAP_SIGNS: "fa fa-map-signs",
    BUILDING: "fa fa-building",
    ARROW_LEFT: "fa fa-arrow-left",
    SALE_CART: "fa fa-shopping-cart",
    ASTERISK: "fa fa-asterisk",
    HOME: "fa fa-home",
    CREDIT_CARD_ALT: "fa fa-credit-card-alt",
    COMMENT: "fa fa-comment-o",
    ARROW_CIRCLE_LEFT: "fa fa-arrow-circle-left",
    WHATSAPP: "fa fa-whatsapp",
    CUBES: "fa fa-cubes",
    LEVELDOWN: "fa fa-level-down",
    STACKOVERFLOW: "fa fa-stack-overflow",
    ROCKET: "fa fa-rocket",
    HISTORY: "fa fa-history",
    QUESTION_CIRLE: "fa fa-question-circle",
    COG: "fa fa-cog",
    FILTER_TASK: "fa fa-tasks",
    EYE: "fa fa-eye",
    EYE_SLASH: "fa fa-eye-slash",
  };

  static DADO = {
    LABEL: "LABEL",
    DATE: "DATE",
    TIME: "TIME",
    DATE_TIME: "DATE_TIME",
    STRING: "STRING",
    INTEGER: "INTEGER",
    CURRENCY: "CURRENCY",
    DECIMAL: "DECIMAL",
    BOOLEAN: "BOOLEAN",
    CEP: "CEP",
    CPF_CNPJ: "CPF_CNPJ",
    ICON: "ICON",
    VALUE: "VALUE",
    NUMBER_CUSTOM: "NUMBER_CUSTOM",
  };

  static APP = {
    IFOOD: "IFOOD",
    SITEMERCADO: "SITEMERCADO",
    WOOCOMMERCE: "WOOCOMMERCE",
  };

  static PEDIDO_IFOOD = {
    CANCELADO: "CANCELADO",
    DESPACHADO: "DESPACHADO",
    CONFIRMADO: "CONFIRMADO",
    COLETADO: "COLETADO",
    INTEGRADO: "INTEGRADO",
    CONCLUIDO: "CONCLUÍDO",
  };

  static PAGAMENTO = {
    DINHEIRO: "DINHEIRO",
    CARTAO: "CARTAO",
    DEBITO: "DEBITO",
    CREDITO: "CREDITO",
    ONLINE: "ON_LINE",
    OFFLINE: "OFF_LINE",
  };

  static ASSINATURA = {
    MENSAL: "MENSAL",
    QUINZENAL: "QUINZENAL",
    SEMANAL: "SEMANAL",
  };

  static POSICAO = {
    TODAS: "TODAS",
    ATIVA: "ATIVA",
    PAUSADA: "PAUSADA",
    CANCELADA: "CANCELADA",
  };

  static OPCOES_ENTREGA = {
    ENTREGA: "ENTREGA",
    RETIRADA: "RETIRADA",
  };

  static ORCAMENTO = {
    TODOS: "TODAS",
    ABERTO: "EM_ABERTO",
    SEPARACAO: "EM_SEPARACAO",
    CONCLUIDO: "CONCLUIDO",
    CANCELADO: "CANCELADO",
  };
}
