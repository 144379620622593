import React, { Component } from "react";

import {
  Form,
  Row,
  Select,
  SelectValue,
  Label,
  CheckboxToggle,
  Notification,
  SectionForm,
  Button,
  InputInteger,
  Table,
  Div,
  Title,
} from "../../components";
import Horario from "./Horario";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import EmpresaService from "../../service/EmpresaService";
import HorariosService from "../../service/HorariosService";
import { Type, Utils } from "../../utilities";
import HorarioEditar from "./HorarioEditar";

const styles = {
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    marginBottom: "35px",
    marginTop: "33px",
  },
  checkedBox: {
    margin: "33px 5px 8px",
  },
};

class HorarioEntregas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEmpresa: "",
      horario: {
        aceitaAgendamentoEntrega: true,

        aceitaEntregaDomingo: false,
        aceitaEntregaSegunda: false,
        aceitaEntregaTerca: false,
        aceitaEntregaQuarta: false,
        aceitaEntregaQuinta: false,
        aceitaEntregaSexta: false,
        aceitaEntregaSabado: false,

        tipo: "ENTREGA",
        descricao: "",
        inicio: "",
        fim: "",
        quantidadeMaxima: 0,
        diasPrevisaoEntrega: 0,
        idEmpresa: "",
      },
      diasPrevisaoEntrega: 0,
      quantidadeMaxima: 0,
      horarios: [],

      tipoHorario: [
        new SelectValue("ENTREGA", "ENTREGA"),
        new SelectValue("RETIRADA", "RETIRADA"),
      ],

      listaHoras: [
        new SelectValue("00:00", "00:00"),
        new SelectValue("00:30", "00:30"),
        new SelectValue("01:00", "01:00"),
        new SelectValue("01:30", "01:30"),
        new SelectValue("02:00", "02:00"),
        new SelectValue("02:30", "02:30"),
        new SelectValue("03:00", "03:00"),
        new SelectValue("03:30", "03:30"),
        new SelectValue("04:00", "04:00"),
        new SelectValue("04:30", "04:30"),
        new SelectValue("05:00", "05:00"),
        new SelectValue("05:30", "05:30"),
        new SelectValue("06:00", "06:00"),
        new SelectValue("06:30", "06:30"),
        new SelectValue("07:00", "07:00"),
        new SelectValue("07:30", "07:30"),
        new SelectValue("08:00", "08:00"),
        new SelectValue("08:30", "08:30"),
        new SelectValue("09:00", "09:00"),
        new SelectValue("09:30", "09:30"),
        new SelectValue("10:00", "10:00"),
        new SelectValue("10:30", "10:30"),
        new SelectValue("11:00", "11:00"),
        new SelectValue("11:30", "11:30"),
        new SelectValue("12:00", "12:00"),
        new SelectValue("12:30", "12:30"),
        new SelectValue("13:00", "13:00"),
        new SelectValue("13:30", "13:30"),
        new SelectValue("14:00", "14:00"),
        new SelectValue("14:30", "14:30"),
        new SelectValue("15:00", "15:00"),
        new SelectValue("15:30", "15:30"),
        new SelectValue("16:00", "16:00"),
        new SelectValue("16:30", "16:30"),
        new SelectValue("17:00", "17:00"),
        new SelectValue("17:30", "17:30"),
        new SelectValue("18:00", "18:00"),
        new SelectValue("18:30", "18:30"),
        new SelectValue("19:00", "19:00"),
        new SelectValue("19:30", "19:30"),
        new SelectValue("20:00", "20:00"),
        new SelectValue("20:30", "20:30"),
        new SelectValue("21:00", "21:00"),
        new SelectValue("21:30", "21:30"),
        new SelectValue("22:00", "22:00"),
        new SelectValue("22:30", "22:30"),
        new SelectValue("23:00", "23:00"),
        new SelectValue("23:30", "23:30"),
        new SelectValue("23:59", "23:59"),
      ],

      closeModal: false,
      loadingHorarios: false,
      idEmpresaHorarioEntrega: "",
    };

    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setValoresDefault();
    this.getParametrosEmpresa();
    this.getHorarios();
  }

  componentDidUpdate(prevState) {
    if (this.state.loadingHorarios) {
      this.getHorarios();
    }
  }

  async getParametrosEmpresa() {
    await EmpresaService.getEmpresaParams().then(({ data }) => {
      this.setState({
        horario: {
          ...this.state.horario,
          ...data,
        },
      });
    });
  }

  async getHorarios() {
    await HorariosService.getHorariosEntrega().then(({ status, data }) => {
      if (status) {
        this.setState({
          horarios: data,
          loadingHorarios: false,
        });
      }
    });
  }

  setValoresDefault() {
    if (!this.isEdit()) {
      this.setState({
        horario: new Horario(this.props.user.idEmpresa),
      });
    }
  }

  setLoadingHorarios = (boolean) => {
    this.setState({
      loadingHorarios: boolean,
    });
  };

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      quantidadeMaxima: 0,
    });
  };

  isEdit() {
    return this.state.edit;
  }

  getTextoBotao() {
    return this.state.edit ? "Editar" : "Salvar";
  }

  submit(event) {
    event.preventDefault();
    if (event.target.reportValidity() && this.validarDiasDaSemana()) {
      const {
        aceitaAgendamentoEntrega,
        aceitaEntregaDomingo,
        aceitaEntregaSegunda,
        aceitaEntregaTerca,
        aceitaEntregaQuarta,
        aceitaEntregaQuinta,
        aceitaEntregaSexta,
        aceitaEntregaSabado,
        diasPrevisaoEntrega,
        idEmpresa,
      } = this.state.horario;

      let newHorario = {
        idEmpresa,
        aceitaEntregaDomingo,
        aceitaEntregaSegunda,
        aceitaEntregaTerca,
        aceitaEntregaQuarta,
        aceitaEntregaQuinta,
        aceitaEntregaSexta,
        aceitaEntregaSabado,
        diasPrevisaoEntrega,
        aceitaAgendamentoEntrega,
      };

      EmpresaService.putEmpresa(newHorario).then(({ data, status }) => {
        if (status) {
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  addNewHorario(e) {
    e.preventDefault();
    const { inicio, fim } = this.state.horario;
    let newHorario = {
      idEmpresaHorarioEntrega: Utils.uuidv4(),
      tipo: "ENTREGA",
      horarioInicial: inicio,
      horarioFinal: fim,
      ativo: true,
      quantidadeMaxima: this.state.quantidadeMaxima,
    };

    this.cadastrarHorario([newHorario]);
  }

  validarDiasDaSemana() {
    let { horario } = this.state;
    let validado =
      horario.aceitaEntregaSegunda ||
      horario.aceitaEntregaTerca ||
      horario.aceitaEntregaQuarta ||
      horario.aceitaEntregaQuinta ||
      horario.aceitaEntregaSexta ||
      horario.aceitaEntregaSabado ||
      horario.aceitaEntregaDomingo;
    if (!validado) {
      Notification.warning("Selecione pelo menos um dia da semana");
    }

    return validado;
  }

  editarHorario(horario) {
    this.setState({
      horario: {
        ...this.state.horario,
        inicio: horario.horarioInicial,
        fim: horario.horarioFinal,
      },
      quantidadeMaxima: horario.quantidadeMaxima,
      closeModal: true,
      idEmpresaHorarioEntrega: horario.idEmpresaHorarioEntrega,
    });
  }

  cadastrarHorario(horario) {
    HorariosService.createHorarios(horario).then(({ status, mensagem }) => {
      if (status) {
        Notification.success(mensagem);
        this.getHorarios();
      } else {
        Notification.danger(mensagem);
      }
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ horario: { ...this.state.horario, [name]: value } });
  };

  handleChangeToogle = (e) => {
    let { name, checked } = e;
    this.setState({ horario: { ...this.state.horario, [name]: checked } });
  };

  handleChangeInicio = (e) => {
    let { name, value } = e.target;
    const { horario } = this.state;

    let horas = Array.from(this.state.listaHoras);
    let indexHoraInicio = horas.indexOf(
      horas.filter((hora) => hora.id === value)[0]
    );
    let indexHoraFim = horas.indexOf(
      horas.filter((hora) => hora.id === horario.fim)[0]
    );

    if (indexHoraInicio < indexHoraFim) {
      this.setState({ horario: { ...this.state.horario, [name]: value } });
    } else {
      this.setState({
        horario: {
          ...this.state.horario,
          [name]: value,
          fim: horas[indexHoraInicio + 1].id,
        },
      });
    }
  };

  handleChangeChecked = (e) => {
    const { name, checked } = e;
    this.setState({
      horario: {
        ...this.state.horario,
        [name]: checked,
      },
    });
  };

  render() {
    const { horario } = this.state;
    let listaHoras = Array.from(this.state.listaHoras);
    let listaHorasInicio = listaHoras.filter(
      (hora, index) => index < listaHoras.length - 1
    );
    let horaInicio = listaHoras.indexOf(
      Array.from(listaHoras).filter((hora) => horario.inicio === hora.id)[0]
    );
    let listaHorasFim = listaHoras.filter((hora, index) => index > horaInicio);

    return (
      <>
        <HorarioEditar
          closeModal={this.state.closeModal}
          setCloseModal={this.setCloseModal}
          setLoadingHorarios={this.setLoadingHorarios}
          listaHoras={this.state.listaHoras}
          quantidadeMaxima={this.state.quantidadeMaxima}
          idEmpresaHorarioEntrega={this.state.idEmpresaHorarioEntrega}
          tipo="ENTREGA"
          horario={horario}
        />
        <Form
          id="formCadastroHorario"
          name="formCadastroHorario"
          handlerSubmit={(e) => e.preventDefault()}
        >
          <SectionForm title="Horário Entregas" borderLeft="primary">
            <Row style={{ padding: "15px", alignItems: "center" }}>
              <InputInteger
                //placeholder="número max"
                disabled={horario.aceitaAgendamentoEntrega}
                responsive="3"
                isInputGroup={false}
                label="Previsão de Entrega em dias"
                name="diasPrevisaoEntrega"
                required
                value={this.state.horario.diasPrevisaoEntrega}
                handlerChange={(e) =>
                  this.setState({
                    horario: {
                      ...this.state.horario,
                      diasPrevisaoEntrega: parseInt(e.target.value),
                    },
                  })
                }
              />
              <Div style={styles.checkedBox}>
                <CheckboxToggle
                  inline="center"
                  name="aceitaAgendamentoEntrega"
                  value={horario.aceitaAgendamentoEntrega}
                  checked={horario.aceitaAgendamentoEntrega}
                  handlerChange={(e) => this.handleChangeChecked(e)}
                ></CheckboxToggle>
              </Div>

              <Title
                value="Agendamento Entrega"
                type="h6"
                className="label-valor-total-carrinho"
                style={styles.labelCinza}
                family="Bold"
              />
            </Row>

            {horario.aceitaAgendamentoEntrega && (
              <>
                <Row col="12">
                  <Label
                    style={{ marginBottom: "20px", marginTop: "10px" }}
                    col="12"
                    value="Selecione os dias da semana"
                  ></Label>
                </Row>
                <Row inline="between" col="10" style={{ paddingLeft: "25px" }}>
                  <CheckboxToggle
                    labelRight="Segunda"
                    name="aceitaEntregaSegunda"
                    checked={horario.aceitaEntregaSegunda}
                    value={horario.aceitaEntregaSegunda}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Terça"
                    name="aceitaEntregaTerca"
                    checked={horario.aceitaEntregaTerca}
                    value={horario.aceitaEntregaTerca}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Quarta"
                    name="aceitaEntregaQuarta"
                    checked={horario.aceitaEntregaQuarta}
                    value={horario.aceitaEntregaQuarta}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Quinta"
                    name="aceitaEntregaQuinta"
                    checked={horario.aceitaEntregaQuinta}
                    value={horario.aceitaEntregaQuinta}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Sexta"
                    name="aceitaEntregaSexta"
                    checked={horario.aceitaEntregaSexta}
                    value={horario.aceitaEntregaSexta}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Sábado"
                    name="aceitaEntregaSabado"
                    checked={horario.aceitaEntregaSabado}
                    value={horario.aceitaEntregaSabado}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>

                  <CheckboxToggle
                    labelRight="Domingo"
                    name="aceitaEntregaDomingo"
                    checked={horario.aceitaEntregaDomingo}
                    value={horario.aceitaEntregaDomingo}
                    handlerChange={this.handleChangeToogle}
                    success
                  ></CheckboxToggle>
                </Row>

                <Row col="12" style={{ marginTop: "25px" }}>
                  <Label col="12" value="Adicionar horários"></Label>
                </Row>

                <Row col="12">
                  <Select
                    responsive="3"
                    label="Das"
                    values={listaHorasInicio}
                    name="inicio"
                    handlerChange={this.handleChangeInicio}
                    required
                    valueSelected={horario.inicio}
                  ></Select>
                  <Select
                    responsive="3"
                    label="Até"
                    values={listaHorasFim}
                    name="fim"
                    handlerChange={this.handleChange}
                    required
                    valueSelected={horario.fim}
                  ></Select>
                  <InputInteger
                    //placeholder="número max"
                    responsive="3"
                    isInputGroup={false}
                    label="Quantidade Máxima"
                    name="quantidadeMaxima"
                    value={this.state.quantidadeMaxima}
                    handlerChange={(e) =>
                      this.setState({
                        quantidadeMaxima: e.target.value,
                      })
                    }
                  />
                  <Div
                    col="3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "17px",
                    }}
                  >
                    <Button
                      type="button"
                      responsive="12"
                      value="Adicionar"
                      btn="primary"
                      float="right"
                      handlerClick={(e) => this.addNewHorario(e)}
                    ></Button>
                  </Div>
                </Row>

                <Div padding="2">
                  <Table
                    columns={[
                      { value: "Início", align: "center" },
                      { value: "Fim", align: "center" },
                      { value: "Quantidade Máxima", align: "center" },
                      { value: "Status", align: "center" },
                    ]}
                    fields={[
                      {
                        value: "horarioInicial",
                        type: Type.DADO.TIME,
                      },
                      {
                        value: "horarioFinal",
                        type: Type.DADO.TIME,
                      },
                      {
                        value: "quantidadeMaxima",
                        type: Type.DADO.STRING,
                      },
                      {
                        value: "ativo",
                        type: Type.DADO.BOOLEAN,
                        valueTrue: "ATIVO",
                        valueFalse: "DESATIVADO",
                      },
                    ]}
                    bordered
                    orientation="center"
                    pagination
                    edit
                    sizeEdit="2"
                    handlerEdit={(rede) => this.editarHorario(rede)}
                    data={this.state.horarios}
                    error="Nenhum horário adicionado!"
                  ></Table>
                </Div>
              </>
            )}
          </SectionForm>

          <Button
            type="button"
            marginBottom="5"
            margin="2"
            value="Salvar"
            btn="primary"
            float="right"
            handlerClick={(e) => this.submit(e)}
          ></Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HorarioEntregas);
