import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Div,
  I,
  Label,
  ModalNotification,
  Notification,
  Row,
} from "../../components";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconDepartamento, { SelectIcon } from "./IconDepartamento";
import MenuDepartamentoService from "../../service/MenuDepartamentoService";
import CheckbuttonLight from "../../components/checkbutton/CheckbuttonLight";
import { Color, Type } from "../../utilities";
import EmpresaService from "../../service/EmpresaService";

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF",
  },
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  let newList = [];

  if (result) {
    result.forEach((item, index) => {
      item.ordem = index + 1;
      newList.push(item);
    });
  }

  return newList;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  height: 92,
  display: "flex",
  flexDirection: "row",

  // change background colour if dragging
  background: isDragging ? "#DEEBFF" : "#FFF",

  boxShadow: isDragging
    ? "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
    : "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#ffebe6" : "rgb(235, 236, 240)",
  padding: grid,
  width: "auto",
  height: "75vh",
  overflowY: "scroll",
});

const orderOptions = {
  abc: "abc",
  qdt: "qdt",
  none: "nenhum",
};

class MenuDepartamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //items: getItems(10),
      listMenu: [],
      icons: [],
      order: orderOptions.none,
      editar: false,
      selectIcon: "",
      descricao: "",
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.getMenu = this.getMenu.bind(this);
    this.setStateIcon = this.setStateIcon.bind(this);
  }

  componentDidMount() {
    this.getMenu();
    this.getIcon();
    this.getParametrosEmpresa();
  }

  getIcon() {
    MenuDepartamentoService.getIconAdmin().then((data) => {
      if (data) {
        this.setState({
          icons: data,
        });
      }
    });
  }

  async getParametrosEmpresa() {
    await EmpresaService.getEmpresaParams().then(({ data }) => {
      this.setState({
        ...this.state,
        ...data,
      });
    });
  }

  sortCresc(list, key = "descricao") {
    let newList = list;

    newList.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  sortDecresc(list, key = "descricao") {
    let newList = list;

    newList.sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  getMenu() {
    MenuDepartamentoService.getMenuAdmin().then((data) => {
      if (data) {
        this.setState({
          listMenu: data,
          editar: false,
        });
      }
    });
  }

  putMenu(list) {
    if (!this.state.editar) {
      Notification.danger(
        "É necessário realizar uma alteração no menu antes de salvar"
      );
    }

    if (this.state.editar) {
      MenuDepartamentoService.putMenu(list).then((data) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getMenu();
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.listMenu,
      result.source.index,
      result.destination.index
    );

    this.setState({
      listMenu: items,
      editar: true,
    });
  }

  setStateIcon(icon) {
    this.setState({
      selectIcon: icon,
    });
  }

  confirmIcon(item) {
    let obj = item;
    obj.icone = this.state.selectIcon;

    MenuDepartamentoService.putMenu([obj]).then((data) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getMenu();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  modalIconsDisponiveis(item) {
    ModalNotification.renderMessage({
      title: "Ícones Disponiveis para Departamento",
      body: (
        <SelectIcon
          icons={this.state.icons}
          itemIcon={item}
          setStateIcon={this.setStateIcon}
        />
      ),
      handlerConfirm: () => this.confirmIcon(item),
      handlerCancel: () => {},
      labelButtonCancel: "Fechar",
    });
  }

  render() {
    return (
      <Div>
        <Div col="12">
          <Div
            inline="start"
            bg={Color.NODE.WHITE}
            colorText={Color.NODE.DEFAULT}
            className="titulo-precificacao"
            style={{
              border: "none",
              borderLeft: "7px solid #63c3d1",
              margin: "0",
              padding: "10px 10px 0px !important",
            }}
          >
            <I icon={Type.ICON.INFO} marginLeft="2" marginBottom="2" />
            <Label
              bold
              value="Arraste os Menus para ordenar sua exibição no catálogo"
              rounded
              marginLeft="2"
            />
          </Div>
          <Row
            padding="3"
            col="12"
            shadow
            className="border-left-primary"
            style={styles.boxInfo}
          >
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="scroll-custom"
                  >
                    {this.state.listMenu.map((item, index) => (
                      <Draggable
                        key={item.chavePesquisa}
                        draggableId={item.chavePesquisa}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Div
                              style={{
                                width: 78,
                                marginRight: 10,
                              }}
                            >
                              <IconDepartamento
                                urlFoto={item.urlFoto}
                                item={item}
                                icons={this.state.icons}
                                getMenu={this.getMenu}
                              />
                            </Div>
                            <Div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                              }}
                              // handleMouseEnter={() =>
                              //   this.setState({ editIcon: true })
                              // }
                              // handleMouseLeave={() =>
                              //   this.setState({ editIcon: false })
                              // }
                            >
                              <span style={{ fontWeight: "bolder" }}>
                                {item.descricao}
                                <I
                                  pointer
                                  title="Editar Icon do Departamento"
                                  icon={Type.ICON.EDIT}
                                  style={{
                                    color: Color.RGBA.LARANJA,
                                    fontSize: 23,
                                    display: "flex",
                                    margin: "-25px -15px 0px 229px",
                                  }}
                                  handlerClick={() =>
                                    this.modalIconsDisponiveis(item)
                                  }
                                />
                              </span>
                              <span
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              >
                                <span>
                                  Chave Pesquisa: {item.chavePesquisa}
                                </span>
                                <span>Ordem: {item.ordem}</span>
                              </span>
                            </Div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Div padding="3">
              <CheckbuttonLight
                title="Ordenar Menu por ordem alfabética"
                col={false ? "12" : "3"}
                style={{ marginBottom: "15px", minWidth: "355px" }}
                Icon={
                  <I
                    pointer
                    icon={Type.ICON.SORT_ABC}
                    style={{
                      color: Color.ECOMMERCE.CINZA_ESCURO,
                      fontSize: 20,
                      margin: "2px 17px 0px 0px",
                    }}
                  />
                }
                checked={this.state.order === orderOptions.abc}
                onClick={() => {
                  if (this.state.order !== orderOptions.abc) {
                    let listNew = this.sortCresc(this.state.listMenu);
                    let newArray = [];

                    if (listNew) {
                      listNew.forEach((item, index) => {
                        item.ordem = index + 1;
                        newArray.push(item);
                      });
                    }
                    this.setState({
                      order: orderOptions.abc,
                      listMenu: newArray,
                    });
                  }
                }}
              />

              {/* <CheckbuttonLight
								title="Ordenar Menu por qtd. de produtos"
								col={false ? "12" : "3"}
								style={{ marginBottom: "15px", minWidth: "355px" }}
								Icon={
									<I
										pointer
										icon={Type.ICON.SORT_QDT}
										style={{
											color: Color.ECOMMERCE.CINZA_ESCURO,
											fontSize: 20,
											margin: "2px 17px 0px 0px",
										}}
									/>
								}
								checked={this.state.order === orderOptions.qdt}
								onClick={() => {
									if (this.state.order !== orderOptions.qdt) {
										let listNew = this.sortDecresc(this.state.listMenu, "quantidadeProduto")
										let newArray = []

										if (listNew) {
											listNew.forEach((item, index) => {
												item.ordem = index + 1
												newArray.push(item)
											})
										}
										this.setState({
											order: orderOptions.qdt,
											listMenu: newArray
										})
									}
								}}
							/> */}
            </Div>

            <Div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
              }}
            >
              <Button
                margin="3"
                value="Salvar"
                btn="primary"
                float="right"
                handlerClick={() => {
                  this.putMenu(this.state.listMenu);
                }}
              ></Button>
            </Div>
          </Row>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuDepartamento);
